import React from "react";
import { Text } from "@mantine/core";
/*
TO DO: 
  - interface for props
  - outline what props should be
  - styles
*/
interface IconProps {
  className?: string;
}

function SticksGame(props: IconProps) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 71 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="71" height="52" rx="2" fill="#D9D9D9" />
      <g filter="url(#filter0_d_283_995)">
        <rect
          x="11.6583"
          y="33.0895"
          width="40.7597"
          height="0.793197"
          rx="0.396599"
          fill="#1C3BDF"
        />
        <rect
          x="11.6833"
          y="33.1145"
          width="40.7097"
          height="0.743197"
          rx="0.371599"
          stroke="#373A41"
          strokeWidth="0.05"
        />
      </g>
      <g filter="url(#filter1_d_283_995)">
        <rect
          width="40.296"
          height="0.804316"
          rx="0.402158"
          transform="matrix(0.952171 -0.305566 0.37177 0.928325 13.4304 38.8106)"
          fill="#FB3939"
        />
        <rect
          x="0.0330985"
          y="0.015569"
          width="40.246"
          height="0.754316"
          rx="0.377158"
          transform="matrix(0.952171 -0.305566 0.37177 0.928325 13.4262 38.8218)"
          stroke="#373A41"
          strokeWidth="0.05"
        />
      </g>
      <g filter="url(#filter2_d_283_995)">
        <rect
          width="38.8913"
          height="0.836357"
          rx="0.418179"
          transform="matrix(0.776603 -0.629991 0.711424 0.702763 26.7216 49.4122)"
          fill="#1C3BDF"
        />
        <rect
          x="0.0372007"
          y="0.00181931"
          width="38.8413"
          height="0.786357"
          rx="0.393179"
          transform="matrix(0.776603 -0.629991 0.711424 0.702763 26.7286 49.4362)"
          stroke="#373A41"
          strokeWidth="0.05"
        />
      </g>
      <g filter="url(#filter3_d_283_995)">
        <rect
          x="15.2026"
          y="25.7043"
          width="40.7597"
          height="0.793197"
          rx="0.396599"
          fill="#FB3939"
        />
        <rect
          x="15.2276"
          y="25.7293"
          width="40.7097"
          height="0.743197"
          rx="0.371599"
          stroke="#373A41"
          strokeWidth="0.05"
        />
      </g>
      <g filter="url(#filter4_d_283_995)">
        <rect
          width="38.9243"
          height="0.83563"
          rx="0.417815"
          transform="matrix(0.781432 0.623991 -0.705861 0.70835 19.3367 18.5655)"
          fill="#FAB005"
        />
        <rect
          x="0.00188927"
          y="0.0333085"
          width="38.8743"
          height="0.78563"
          rx="0.392815"
          transform="matrix(0.781432 0.623991 -0.705861 0.70835 19.3607 18.5741)"
          stroke="#373A41"
          strokeWidth="0.05"
        />
      </g>
      <g filter="url(#filter5_d_283_995)">
        <rect
          width="40.5939"
          height="0.797206"
          rx="0.398603"
          transform="matrix(0.98335 -0.18172 0.224712 0.974425 21.4052 38.6334)"
          fill="#FAB005"
        />
        <rect
          x="0.0302016"
          y="0.0198176"
          width="40.5439"
          height="0.747206"
          rx="0.373603"
          transform="matrix(0.98335 -0.18172 0.224712 0.974425 21.4012 38.6394)"
          stroke="#373A41"
          strokeWidth="0.05"
        />
      </g>
      <g filter="url(#filter6_d_283_995)">
        <rect
          width="40.2215"
          height="0.806075"
          rx="0.403038"
          transform="matrix(0.944106 0.329641 -0.399446 0.916757 15.5246 24.1179)"
          fill="#4D8B31"
        />
        <rect
          x="0.0136165"
          y="0.0311599"
          width="40.1715"
          height="0.756075"
          rx="0.378038"
          transform="matrix(0.944106 0.329641 -0.399446 0.916757 15.5378 24.116)"
          stroke="#373A41"
          strokeWidth="0.05"
        />
      </g>
      <g filter="url(#filter7_d_283_995)">
        <rect
          width="40.5654"
          height="0.79789"
          rx="0.398945"
          transform="matrix(0.980444 -0.196798 0.242979 0.970032 11.6583 30.5147)"
          fill="#4D8B31"
        />
        <rect
          x="0.0305856"
          y="0.0193308"
          width="40.5154"
          height="0.74789"
          rx="0.373945"
          transform="matrix(0.980444 -0.196798 0.242979 0.970032 11.6542 30.5213)"
          stroke="#373A41"
          strokeWidth="0.05"
        />
      </g>
      <g filter="url(#filter8_d_283_995)">
        <rect
          width="39.5299"
          height="0.822086"
          rx="0.411043"
          transform="matrix(0.863201 0.50486 -0.589541 0.807739 9.48465 27.2907)"
          fill="#1C3BDF"
        />
        <rect
          x="0.00684151"
          y="0.032815"
          width="39.4799"
          height="0.772086"
          rx="0.386043"
          transform="matrix(0.863201 0.50486 -0.589541 0.807739 9.50493 27.2936)"
          stroke="#373A41"
          strokeWidth="0.05"
        />
      </g>
      <g filter="url(#filter9_d_283_995)">
        <rect
          x="17.8608"
          y="21.7383"
          width="40.7597"
          height="0.793197"
          rx="0.396599"
          fill="#FB3939"
        />
        <rect
          x="17.8858"
          y="21.7633"
          width="40.7097"
          height="0.743197"
          rx="0.371599"
          stroke="#373A41"
          strokeWidth="0.05"
        />
      </g>
      <g filter="url(#filter10_d_283_995)">
        <rect
          x="13.4304"
          y="31.2567"
          width="40.7597"
          height="0.793197"
          rx="0.396599"
          fill="#1C3BDF"
        />
        <rect
          x="13.4554"
          y="31.2817"
          width="40.7097"
          height="0.743197"
          rx="0.371599"
          stroke="#373A41"
          strokeWidth="0.05"
        />
      </g>
      <g filter="url(#filter11_d_283_995)">
        <rect
          width="40.4967"
          height="0.799537"
          rx="0.399768"
          transform="matrix(0.97337 0.229238 -0.28197 0.959423 14.542 31.2567)"
          fill="#FB3939"
        />
        <rect
          x="0.017285"
          y="0.0297165"
          width="40.4468"
          height="0.749537"
          rx="0.374768"
          transform="matrix(0.97337 0.229238 -0.28197 0.959423 14.5508 31.2539)"
          stroke="#373A41"
          strokeWidth="0.05"
        />
      </g>
      <g filter="url(#filter12_d_283_995)">
        <rect
          x="15.2026"
          y="29.6703"
          width="40.7597"
          height="0.793197"
          rx="0.396599"
          fill="#FAB005"
        />
        <rect
          x="15.2276"
          y="29.6953"
          width="40.7097"
          height="0.743197"
          rx="0.371599"
          stroke="#373A41"
          strokeWidth="0.05"
        />
      </g>
      <g filter="url(#filter13_d_283_995)">
        <rect
          width="38.2192"
          height="0.850874"
          rx="0.425437"
          transform="matrix(0.667768 -0.74437 0.811968 0.583702 18.7469 46.2215)"
          fill="#4D8B31"
        />
        <rect
          x="0.0369934"
          y="-0.00401669"
          width="38.1692"
          height="0.800874"
          rx="0.400437"
          transform="matrix(0.667768 -0.74437 0.811968 0.583702 18.7625 46.2474)"
          stroke="#373A41"
          strokeWidth="0.05"
        />
      </g>
      <g filter="url(#filter14_d_283_995)">
        <rect
          width="40.5954"
          height="0.797169"
          rx="0.398584"
          transform="matrix(0.983508 -0.180867 0.223677 0.974663 13.4304 42.565)"
          fill="#4D8B31"
        />
        <rect
          x="0.0301796"
          y="0.0198449"
          width="40.5454"
          height="0.747169"
          rx="0.373584"
          transform="matrix(0.983508 -0.180867 0.223677 0.974663 13.4265 42.571)"
          stroke="#373A41"
          strokeWidth="0.05"
        />
      </g>
      <path
        d="M16.9836 8.0644C16.6862 8.0644 16.427 7.99693 16.2062 7.862C15.9854 7.724 15.8137 7.52927 15.691 7.2778C15.5714 7.02327 15.5116 6.72733 15.5116 6.39C15.5116 6.05573 15.573 5.76287 15.6956 5.5114C15.8214 5.25687 15.9946 5.0606 16.2154 4.9226C16.4362 4.7846 16.6862 4.7156 16.9652 4.7156C17.2106 4.7156 17.4436 4.7616 17.6644 4.8536L17.6184 5.2446C17.4222 5.14647 17.232 5.0974 17.048 5.0974C16.8211 5.0974 16.6264 5.15413 16.4638 5.2676C16.3044 5.38107 16.1832 5.53593 16.1004 5.7322C16.0176 5.92847 15.9762 6.14773 15.9762 6.39C15.9762 6.63227 16.0176 6.85153 16.1004 7.0478C16.1832 7.24407 16.3044 7.39893 16.4638 7.5124C16.6264 7.62587 16.8226 7.6826 17.0526 7.6826C17.252 7.6826 17.4406 7.6366 17.6184 7.5446L17.6644 7.9356C17.4682 8.02147 17.2412 8.0644 16.9836 8.0644Z"
        fill="#212529"
      />
      <path
        d="M18.4851 8.046C18.3134 8.046 18.1723 7.98467 18.0619 7.862C17.9515 7.73933 17.8963 7.58293 17.8963 7.3928C17.8963 7.11067 18.0144 6.90827 18.2505 6.7856C18.4867 6.65987 18.7504 6.59393 19.0417 6.5878C19.0417 6.39153 19.0126 6.25507 18.9543 6.1784C18.8961 6.10173 18.7979 6.0634 18.6599 6.0634C18.4821 6.0634 18.3011 6.1324 18.1171 6.2704L18.0665 5.9346C18.2597 5.7782 18.4836 5.7 18.7381 5.7C18.9804 5.7 19.1598 5.77053 19.2763 5.9116C19.3929 6.0496 19.4511 6.27347 19.4511 6.5832V7.241C19.4511 7.4158 19.4634 7.54 19.4879 7.6136C19.5125 7.68413 19.5661 7.7194 19.6489 7.7194H19.6995L19.6489 8.046H19.5615C19.4205 8.046 19.3162 8.01993 19.2487 7.9678C19.1813 7.91567 19.1261 7.83133 19.0831 7.7148C19.0126 7.816 18.9252 7.89727 18.8209 7.9586C18.7167 8.01687 18.6047 8.046 18.4851 8.046ZM18.6093 7.7194C18.6829 7.7194 18.7596 7.6918 18.8393 7.6366C18.9191 7.57833 18.9865 7.5032 19.0417 7.4112V6.873C18.8056 6.88527 18.6262 6.93127 18.5035 7.011C18.3809 7.08767 18.3195 7.20727 18.3195 7.3698C18.3195 7.60287 18.4161 7.7194 18.6093 7.7194Z"
        fill="#212529"
      />
      <path
        d="M20.0427 5.746H20.3693L20.4199 6.1232C20.5211 5.976 20.6161 5.86867 20.7051 5.8012C20.7971 5.73373 20.8937 5.7 20.9949 5.7C21.0347 5.7 21.07 5.7046 21.1007 5.7138L21.0593 6.1692C21.0194 6.16 20.9734 6.1554 20.9213 6.1554C20.8201 6.1554 20.725 6.18147 20.6361 6.2336C20.5471 6.28267 20.4827 6.34093 20.4429 6.4084V8H20.0427V5.746Z"
        fill="#212529"
      />
      <path
        d="M22.2926 8.046C22.0656 8.046 21.8709 7.99387 21.7084 7.8896C21.5489 7.78227 21.4278 7.63813 21.345 7.4572C21.2652 7.27627 21.2254 7.0754 21.2254 6.8546C21.2254 6.643 21.2622 6.4498 21.3358 6.275C21.4124 6.09713 21.5213 5.9576 21.6624 5.8564C21.8065 5.75213 21.9767 5.7 22.173 5.7C22.4244 5.7 22.6284 5.78433 22.7848 5.953C22.9442 6.12167 23.024 6.35473 23.024 6.6522C23.024 6.70433 23.0178 6.7764 23.0056 6.8684H21.6348C21.644 7.1352 21.7099 7.3376 21.8326 7.4756C21.9583 7.6136 22.1208 7.6826 22.3202 7.6826C22.4152 7.6826 22.5149 7.67187 22.6192 7.6504C22.7265 7.62587 22.82 7.59213 22.8998 7.5492L22.9366 7.908C22.7679 8 22.5532 8.046 22.2926 8.046ZM22.6146 6.5878C22.6146 6.43447 22.5762 6.31027 22.4996 6.2152C22.426 6.11707 22.3186 6.068 22.1776 6.068C22.0396 6.068 21.9246 6.11093 21.8326 6.1968C21.7436 6.28267 21.6838 6.413 21.6532 6.5878H22.6146Z"
        fill="#212529"
      />
      <path
        d="M23.4998 6.0634H23.187L23.233 5.746H23.4998V5.1434C23.4998 4.96553 23.5504 4.82293 23.6516 4.7156C23.7528 4.6052 23.897 4.55 24.084 4.55C24.1975 4.55 24.2972 4.56687 24.383 4.6006L24.3324 4.9456C24.268 4.92413 24.2067 4.9134 24.1484 4.9134C24.0687 4.9134 24.0074 4.93947 23.9644 4.9916C23.9215 5.04067 23.9 5.1112 23.9 5.2032V5.746H24.3968V6.0634H23.9V8H23.4998V6.0634Z"
        fill="#212529"
      />
      <path
        d="M26.338 5.746V8H25.9976L25.97 7.724C25.9087 7.8252 25.8259 7.90493 25.7216 7.9632C25.6173 8.0184 25.5023 8.046 25.3766 8.046C25.1405 8.046 24.9641 7.9678 24.8476 7.8114C24.7341 7.65193 24.6774 7.425 24.6774 7.1306V5.746H25.0776V7.08C25.0776 7.2824 25.1067 7.4342 25.165 7.5354C25.2263 7.63353 25.3275 7.6826 25.4686 7.6826C25.5667 7.6826 25.6557 7.655 25.7354 7.5998C25.8151 7.5446 25.8826 7.46793 25.9378 7.3698V5.746H26.338Z"
        fill="#212529"
      />
      <path
        d="M27.408 8.046C27.2363 8.046 27.109 7.99387 27.0262 7.8896C26.9434 7.78533 26.902 7.65653 26.902 7.5032V4.55H27.3022V7.264C27.3022 7.39893 27.3083 7.4986 27.3206 7.563C27.3359 7.62433 27.3635 7.66573 27.4034 7.6872C27.4433 7.70867 27.5077 7.7194 27.5966 7.7194L27.546 8.046H27.408Z"
        fill="#212529"
      />
      <path
        d="M28.4547 8.046C28.283 8.046 28.1557 7.99387 28.0729 7.8896C27.9901 7.78533 27.9487 7.65653 27.9487 7.5032V4.55H28.3489V7.264C28.3489 7.39893 28.355 7.4986 28.3673 7.563C28.3826 7.62433 28.4102 7.66573 28.4501 7.6872C28.49 7.70867 28.5544 7.7194 28.6433 7.7194L28.5927 8.046H28.4547Z"
        fill="#212529"
      />
      <path
        d="M29.5437 7.8988L28.7203 5.746H29.1481L29.7507 7.425L30.3027 5.746H30.7305L29.5207 9.1132H29.0929L29.5437 7.8988Z"
        fill="#212529"
      />
      <path
        d="M32.8817 5.7C33.0627 5.7 33.2206 5.75213 33.3555 5.8564C33.4935 5.9576 33.5978 6.09713 33.6683 6.275C33.7419 6.45287 33.7787 6.6522 33.7787 6.873C33.7787 7.21647 33.7005 7.4986 33.5441 7.7194C33.3877 7.93713 33.1669 8.046 32.8817 8.046C32.7652 8.046 32.6563 8.02607 32.5551 7.9862C32.457 7.94327 32.3727 7.88193 32.3021 7.8022V9.1132H31.9019V5.746H32.2101L32.2469 6.0174C32.4095 5.8058 32.6211 5.7 32.8817 5.7ZM32.8081 7.6918C32.9829 7.6918 33.1194 7.61973 33.2175 7.4756C33.3187 7.33147 33.3693 7.1306 33.3693 6.873C33.3693 6.61847 33.3187 6.41913 33.2175 6.275C33.1194 6.13087 32.9829 6.0588 32.8081 6.0588C32.7161 6.0588 32.6272 6.08947 32.5413 6.1508C32.4555 6.21213 32.3849 6.30413 32.3297 6.4268C32.2776 6.54947 32.2515 6.6982 32.2515 6.873C32.2515 7.05393 32.2776 7.20573 32.3297 7.3284C32.3849 7.45107 32.4539 7.54307 32.5367 7.6044C32.6226 7.66267 32.7131 7.6918 32.8081 7.6918Z"
        fill="#212529"
      />
      <path
        d="M34.1482 4.78H34.5484V5.2354H34.1482V4.78ZM34.1482 5.746H34.5484V8H34.1482V5.746Z"
        fill="#212529"
      />
      <path
        d="M35.9795 8.046C35.636 8.046 35.3754 7.93867 35.1975 7.724C35.0196 7.50627 34.9307 7.2272 34.9307 6.8868C34.9307 6.53413 35.0196 6.24893 35.1975 6.0312C35.3754 5.8104 35.6391 5.7 35.9887 5.7C36.1758 5.7 36.3429 5.73833 36.4901 5.815L36.4257 6.1692C36.2877 6.0956 36.1436 6.0588 35.9933 6.0588C35.7878 6.0588 35.6268 6.13087 35.5103 6.275C35.3968 6.41607 35.3401 6.61387 35.3401 6.8684C35.3401 7.12293 35.3984 7.3238 35.5149 7.471C35.6345 7.6182 35.7986 7.6918 36.0071 7.6918C36.1666 7.6918 36.3076 7.655 36.4303 7.5814L36.4855 7.9402C36.4242 7.97087 36.3475 7.9954 36.2555 8.0138C36.1635 8.03527 36.0715 8.046 35.9795 8.046Z"
        fill="#212529"
      />
      <path
        d="M36.8708 4.55H37.271V6.7672L37.9472 5.746H38.4256L37.6804 6.7856L38.4394 8H37.9564L37.271 6.85V8H36.8708V4.55Z"
        fill="#212529"
      />
      <path
        d="M41.279 5.746V8H40.9386L40.911 7.724C40.8497 7.8252 40.7669 7.90493 40.6626 7.9632C40.5584 8.0184 40.4434 8.046 40.3176 8.046C40.0815 8.046 39.9052 7.9678 39.7886 7.8114C39.6752 7.65193 39.6184 7.425 39.6184 7.1306V5.746H40.0186V7.08C40.0186 7.2824 40.0478 7.4342 40.106 7.5354C40.1674 7.63353 40.2686 7.6826 40.4096 7.6826C40.5078 7.6826 40.5967 7.655 40.6764 7.5998C40.7562 7.5446 40.8236 7.46793 40.8788 7.3698V5.746H41.279Z"
        fill="#212529"
      />
      <path
        d="M42.8274 5.7C43.0084 5.7 43.1663 5.75213 43.3012 5.8564C43.4392 5.9576 43.5435 6.09713 43.614 6.275C43.6876 6.45287 43.7244 6.6522 43.7244 6.873C43.7244 7.21647 43.6462 7.4986 43.4898 7.7194C43.3334 7.93713 43.1126 8.046 42.8274 8.046C42.7109 8.046 42.602 8.02607 42.5008 7.9862C42.4027 7.94327 42.3184 7.88193 42.2478 7.8022V9.1132H41.8476V5.746H42.1558L42.1926 6.0174C42.3552 5.8058 42.5668 5.7 42.8274 5.7ZM42.7538 7.6918C42.9286 7.6918 43.0651 7.61973 43.1632 7.4756C43.2644 7.33147 43.315 7.1306 43.315 6.873C43.315 6.61847 43.2644 6.41913 43.1632 6.275C43.0651 6.13087 42.9286 6.0588 42.7538 6.0588C42.6618 6.0588 42.5729 6.08947 42.487 6.1508C42.4012 6.21213 42.3306 6.30413 42.2754 6.4268C42.2233 6.54947 42.1972 6.6982 42.1972 6.873C42.1972 7.05393 42.2233 7.20573 42.2754 7.3284C42.3306 7.45107 42.3996 7.54307 42.4824 7.6044C42.5683 7.66267 42.6588 7.6918 42.7538 7.6918Z"
        fill="#212529"
      />
      <path
        d="M45.3145 5.4056C45.1888 5.53747 45.0523 5.631 44.9051 5.6862L44.8499 5.2814C44.9603 5.23847 45.0707 5.171 45.1811 5.079C45.2946 4.98393 45.3804 4.88427 45.4387 4.78H45.7331V8H45.3145V5.4056Z"
        fill="#212529"
      />
      <path
        d="M47.8044 8.046C47.5406 8.046 47.3122 7.98467 47.119 7.862L47.1696 7.4572C47.3658 7.61053 47.5682 7.6872 47.7768 7.6872C47.8872 7.6872 47.973 7.6642 48.0344 7.6182C48.0988 7.5722 48.131 7.50473 48.131 7.4158C48.131 7.34833 48.1156 7.29313 48.085 7.2502C48.0574 7.20727 48.0098 7.16587 47.9424 7.126C47.8749 7.08613 47.7706 7.034 47.6296 6.9696C47.464 6.89293 47.3428 6.80553 47.2662 6.7074C47.1895 6.60927 47.1512 6.482 47.1512 6.3256C47.1512 6.1416 47.2156 5.99133 47.3444 5.8748C47.4732 5.75827 47.6464 5.7 47.8642 5.7C48.085 5.7 48.2782 5.74907 48.4438 5.8472L48.3932 6.2198C48.3104 6.1646 48.2322 6.12473 48.1586 6.1002C48.085 6.0726 47.9976 6.0588 47.8964 6.0588C47.789 6.0588 47.7047 6.0818 47.6434 6.1278C47.582 6.1738 47.5514 6.2382 47.5514 6.321C47.5514 6.4038 47.5774 6.46667 47.6296 6.5096C47.6848 6.55253 47.7906 6.60927 47.947 6.6798C48.1647 6.77793 48.318 6.8776 48.407 6.9788C48.4959 7.07693 48.5404 7.20727 48.5404 7.3698C48.5404 7.5814 48.4744 7.747 48.3426 7.8666C48.2107 7.9862 48.0313 8.046 47.8044 8.046Z"
        fill="#212529"
      />
      <path
        d="M49.531 8.046C49.3562 8.046 49.2259 7.99387 49.14 7.8896C49.0572 7.78533 49.0158 7.65807 49.0158 7.5078V6.0634H48.703L48.7398 5.746H49.0158V5.1894L49.416 5.148V5.746H49.9036V6.0634H49.416V7.3468C49.416 7.4848 49.4405 7.5814 49.4896 7.6366C49.5417 7.6918 49.6307 7.7194 49.7564 7.7194H49.899L49.8622 8.046H49.531Z"
        fill="#212529"
      />
      <path
        d="M50.2617 4.78H50.6619V5.2354H50.2617V4.78ZM50.2617 5.746H50.6619V8H50.2617V5.746Z"
        fill="#212529"
      />
      <path
        d="M52.093 8.046C51.7495 8.046 51.4888 7.93867 51.311 7.724C51.1331 7.50627 51.0442 7.2272 51.0442 6.8868C51.0442 6.53413 51.1331 6.24893 51.311 6.0312C51.4888 5.8104 51.7526 5.7 52.1022 5.7C52.2892 5.7 52.4564 5.73833 52.6036 5.815L52.5392 6.1692C52.4012 6.0956 52.257 6.0588 52.1068 6.0588C51.9013 6.0588 51.7403 6.13087 51.6238 6.275C51.5103 6.41607 51.4536 6.61387 51.4536 6.8684C51.4536 7.12293 51.5118 7.3238 51.6284 7.471C51.748 7.6182 51.912 7.6918 52.1206 7.6918C52.28 7.6918 52.4211 7.655 52.5438 7.5814L52.599 7.9402C52.5376 7.97087 52.461 7.9954 52.369 8.0138C52.277 8.03527 52.185 8.046 52.093 8.046Z"
        fill="#212529"
      />
      <path
        d="M52.9843 4.55H53.3845V6.7672L54.0607 5.746H54.5391L53.7939 6.7856L54.5529 8H54.0699L53.3845 6.85V8H52.9843V4.55Z"
        fill="#212529"
      />
      <path
        d="M7.01163 14.046C6.66816 14.046 6.4075 13.9387 6.22963 13.724C6.05176 13.5063 5.96283 13.2272 5.96283 12.8868C5.96283 12.5341 6.05176 12.2489 6.22963 12.0312C6.4075 11.8104 6.67123 11.7 7.02083 11.7C7.2079 11.7 7.37503 11.7383 7.52223 11.815L7.45783 12.1692C7.31983 12.0956 7.1757 12.0588 7.02543 12.0588C6.81996 12.0588 6.65896 12.1309 6.54243 12.275C6.42896 12.4161 6.37223 12.6139 6.37223 12.8684C6.37223 13.1229 6.4305 13.3238 6.54703 13.471C6.66663 13.6182 6.8307 13.6918 7.03923 13.6918C7.1987 13.6918 7.33976 13.655 7.46243 13.5814L7.51763 13.9402C7.4563 13.9709 7.37963 13.9954 7.28763 14.0138C7.19563 14.0353 7.10363 14.046 7.01163 14.046Z"
        fill="#212529"
      />
      <path
        d="M8.32155 14.046C8.14982 14.046 8.00875 13.9847 7.89835 13.862C7.78795 13.7393 7.73275 13.5829 7.73275 13.3928C7.73275 13.1107 7.85082 12.9083 8.08695 12.7856C8.32308 12.6599 8.58682 12.5939 8.87815 12.5878C8.87815 12.3915 8.84902 12.2551 8.79075 12.1784C8.73248 12.1017 8.63435 12.0634 8.49635 12.0634C8.31848 12.0634 8.13755 12.1324 7.95355 12.2704L7.90295 11.9346C8.09615 11.7782 8.32002 11.7 8.57455 11.7C8.81682 11.7 8.99622 11.7705 9.11275 11.9116C9.22928 12.0496 9.28755 12.2735 9.28755 12.5832V13.241C9.28755 13.4158 9.29982 13.54 9.32435 13.6136C9.34888 13.6841 9.40255 13.7194 9.48535 13.7194H9.53595L9.48535 14.046H9.39795C9.25688 14.046 9.15262 14.0199 9.08515 13.9678C9.01768 13.9157 8.96248 13.8313 8.91955 13.7148C8.84902 13.816 8.76162 13.8973 8.65735 13.9586C8.55308 14.0169 8.44115 14.046 8.32155 14.046ZM8.44575 13.7194C8.51935 13.7194 8.59602 13.6918 8.67575 13.6366C8.75548 13.5783 8.82295 13.5032 8.87815 13.4112V12.873C8.64202 12.8853 8.46262 12.9313 8.33995 13.011C8.21728 13.0877 8.15595 13.2073 8.15595 13.3698C8.15595 13.6029 8.25255 13.7194 8.44575 13.7194Z"
        fill="#212529"
      />
      <path
        d="M10.8359 11.7C11.0689 11.7 11.2468 11.792 11.3695 11.976C11.4952 12.1569 11.5581 12.4023 11.5581 12.712V14H11.1579V12.7626C11.1579 12.5234 11.1226 12.3471 11.0521 12.2336C10.9846 12.1171 10.8819 12.0588 10.7439 12.0588C10.6427 12.0588 10.5491 12.0879 10.4633 12.1462C10.3774 12.2014 10.3053 12.2796 10.2471 12.3808V14H9.84688V11.746H10.1873L10.2149 12.0266C10.2793 11.9254 10.3651 11.8457 10.4725 11.7874C10.5829 11.7291 10.704 11.7 10.8359 11.7Z"
        fill="#212529"
      />
      <path
        d="M12.9831 11.7C13.2162 11.7 13.3941 11.792 13.5167 11.976C13.6425 12.1569 13.7053 12.4023 13.7053 12.712V14H13.3051V12.7626C13.3051 12.5234 13.2699 12.3471 13.1993 12.2336C13.1319 12.1171 13.0291 12.0588 12.8911 12.0588C12.7899 12.0588 12.6964 12.0879 12.6105 12.1462C12.5247 12.2014 12.4526 12.2796 12.3943 12.3808V14H11.9941V11.746H12.3345L12.3621 12.0266C12.4265 11.9254 12.5124 11.8457 12.6197 11.7874C12.7301 11.7291 12.8513 11.7 12.9831 11.7Z"
        fill="#212529"
      />
      <path
        d="M15.0016 14.046C14.7992 14.046 14.6244 13.9954 14.4772 13.8942C14.33 13.7899 14.2165 13.6489 14.1368 13.471C14.0601 13.2931 14.0218 13.0938 14.0218 12.873C14.0218 12.6522 14.0601 12.4529 14.1368 12.275C14.2165 12.0971 14.33 11.9576 14.4772 11.8564C14.6244 11.7521 14.7992 11.7 15.0016 11.7C15.2009 11.7 15.3742 11.7521 15.5214 11.8564C15.6686 11.9576 15.7805 12.0971 15.8572 12.275C15.9339 12.4529 15.9722 12.6522 15.9722 12.873C15.9722 13.0938 15.9339 13.2931 15.8572 13.471C15.7805 13.6489 15.6686 13.7899 15.5214 13.8942C15.3742 13.9954 15.2009 14.046 15.0016 14.046ZM15.0016 13.6918C15.1795 13.6918 15.3175 13.6197 15.4156 13.4756C15.5137 13.3315 15.5628 13.1306 15.5628 12.873C15.5628 12.6154 15.5137 12.4161 15.4156 12.275C15.3175 12.1309 15.1795 12.0588 15.0016 12.0588C14.8176 12.0588 14.6765 12.1309 14.5784 12.275C14.4803 12.4161 14.4312 12.6154 14.4312 12.873C14.4312 13.1337 14.4803 13.3361 14.5784 13.4802C14.6765 13.6213 14.8176 13.6918 15.0016 13.6918Z"
        fill="#212529"
      />
      <path
        d="M16.9873 14.046C16.8125 14.046 16.6822 13.9939 16.5963 13.8896C16.5135 13.7853 16.4721 13.6581 16.4721 13.5078V12.0634H16.1593L16.1961 11.746H16.4721V11.1894L16.8723 11.148V11.746H17.3599V12.0634H16.8723V13.3468C16.8723 13.4848 16.8969 13.5814 16.9459 13.6366C16.9981 13.6918 17.087 13.7194 17.2127 13.7194H17.3553L17.3185 14.046H16.9873Z"
        fill="#212529"
      />
      <path
        d="M19.5642 11.7C19.7451 11.7 19.9015 11.7537 20.0334 11.861C20.1683 11.9683 20.2711 12.1125 20.3416 12.2934C20.4121 12.4713 20.4474 12.6645 20.4474 12.873C20.4474 13.0907 20.4106 13.2901 20.337 13.471C20.2634 13.6489 20.1591 13.7899 20.0242 13.8942C19.8923 13.9954 19.739 14.046 19.5642 14.046C19.4415 14.046 19.3265 14.023 19.2192 13.977C19.1149 13.931 19.0275 13.8651 18.957 13.7792L18.9386 14H18.5752V10.55H18.9754V11.9622C19.1349 11.7874 19.3311 11.7 19.5642 11.7ZM19.4952 13.6918C19.6577 13.6918 19.7881 13.6136 19.8862 13.4572C19.9874 13.2977 20.038 13.103 20.038 12.873C20.038 12.6369 19.9889 12.4421 19.8908 12.2888C19.7927 12.1355 19.6608 12.0588 19.4952 12.0588C19.3296 12.0588 19.1947 12.1293 19.0904 12.2704C18.9892 12.4084 18.9386 12.6093 18.9386 12.873C18.9386 13.1459 18.9892 13.3514 19.0904 13.4894C19.1947 13.6243 19.3296 13.6918 19.4952 13.6918Z"
        fill="#212529"
      />
      <path
        d="M21.8007 14.046C21.5737 14.046 21.379 13.9939 21.2165 13.8896C21.057 13.7823 20.9359 13.6381 20.8531 13.4572C20.7733 13.2763 20.7335 13.0754 20.7335 12.8546C20.7335 12.643 20.7703 12.4498 20.8439 12.275C20.9205 12.0971 21.0294 11.9576 21.1705 11.8564C21.3146 11.7521 21.4848 11.7 21.6811 11.7C21.9325 11.7 22.1365 11.7843 22.2929 11.953C22.4523 12.1217 22.5321 12.3547 22.5321 12.6522C22.5321 12.7043 22.5259 12.7764 22.5137 12.8684H21.1429C21.1521 13.1352 21.218 13.3376 21.3407 13.4756C21.4664 13.6136 21.6289 13.6826 21.8283 13.6826C21.9233 13.6826 22.023 13.6719 22.1273 13.6504C22.2346 13.6259 22.3281 13.5921 22.4079 13.5492L22.4447 13.908C22.276 14 22.0613 14.046 21.8007 14.046ZM22.1227 12.5878C22.1227 12.4345 22.0843 12.3103 22.0077 12.2152C21.9341 12.1171 21.8267 12.068 21.6857 12.068C21.5477 12.068 21.4327 12.1109 21.3407 12.1968C21.2517 12.2827 21.1919 12.413 21.1613 12.5878H22.1227Z"
        fill="#212529"
      />
      <path
        d="M25.3969 11.746V14H25.0565L25.0289 13.724C24.9676 13.8252 24.8848 13.9049 24.7805 13.9632C24.6762 14.0184 24.5612 14.046 24.4355 14.046C24.1994 14.046 24.023 13.9678 23.9065 13.8114C23.793 13.6519 23.7363 13.425 23.7363 13.1306V11.746H24.1365V13.08C24.1365 13.2824 24.1656 13.4342 24.2239 13.5354C24.2852 13.6335 24.3864 13.6826 24.5275 13.6826C24.6256 13.6826 24.7146 13.655 24.7943 13.5998C24.874 13.5446 24.9415 13.4679 24.9967 13.3698V11.746H25.3969Z"
        fill="#212529"
      />
      <path
        d="M26.9269 11.7C27.16 11.7 27.3378 11.792 27.4605 11.976C27.5862 12.1569 27.6491 12.4023 27.6491 12.712V14H27.2489V12.7626C27.2489 12.5234 27.2136 12.3471 27.1431 12.2336C27.0756 12.1171 26.9729 12.0588 26.8349 12.0588C26.7337 12.0588 26.6402 12.0879 26.5543 12.1462C26.4684 12.2014 26.3964 12.2796 26.3381 12.3808V14H25.9379V11.746H26.2783L26.3059 12.0266C26.3703 11.9254 26.4562 11.8457 26.5635 11.7874C26.6739 11.7291 26.795 11.7 26.9269 11.7Z"
        fill="#212529"
      />
      <path
        d="M29.8654 10.55V14H29.5112L29.479 13.793C29.4084 13.8758 29.3241 13.9387 29.226 13.9816C29.1278 14.0245 29.0205 14.046 28.904 14.046C28.7292 14.046 28.5728 13.9954 28.4348 13.8942C28.2968 13.7899 28.1879 13.6489 28.1082 13.471C28.0284 13.2931 27.9886 13.0953 27.9886 12.8776C27.9886 12.666 28.0254 12.4713 28.099 12.2934C28.1756 12.1125 28.283 11.9683 28.421 11.861C28.562 11.7537 28.723 11.7 28.904 11.7C29.1278 11.7 29.3149 11.7782 29.4652 11.9346V10.55H29.8654ZM28.9454 13.6918C29.114 13.6918 29.249 13.6259 29.3502 13.494C29.4514 13.3591 29.502 13.1536 29.502 12.8776C29.502 12.6108 29.4514 12.4084 29.3502 12.2704C29.249 12.1293 29.114 12.0588 28.9454 12.0588C28.8411 12.0588 28.7476 12.0941 28.6648 12.1646C28.582 12.2351 28.516 12.3333 28.467 12.459C28.421 12.5817 28.398 12.7212 28.398 12.8776C28.398 13.0248 28.421 13.1613 28.467 13.287C28.516 13.4097 28.582 13.5078 28.6648 13.5814C28.7506 13.655 28.8442 13.6918 28.9454 13.6918Z"
        fill="#212529"
      />
      <path
        d="M31.3106 14.046C31.0837 14.046 30.889 13.9939 30.7264 13.8896C30.567 13.7823 30.4458 13.6381 30.363 13.4572C30.2833 13.2763 30.2434 13.0754 30.2434 12.8546C30.2434 12.643 30.2802 12.4498 30.3538 12.275C30.4305 12.0971 30.5394 11.9576 30.6804 11.8564C30.8246 11.7521 30.9948 11.7 31.191 11.7C31.4425 11.7 31.6464 11.7843 31.8028 11.953C31.9623 12.1217 32.042 12.3547 32.042 12.6522C32.042 12.7043 32.0359 12.7764 32.0236 12.8684H30.6528C30.662 13.1352 30.728 13.3376 30.8506 13.4756C30.9764 13.6136 31.1389 13.6826 31.3382 13.6826C31.4333 13.6826 31.533 13.6719 31.6372 13.6504C31.7446 13.6259 31.8381 13.5921 31.9178 13.5492L31.9546 13.908C31.786 14 31.5713 14.046 31.3106 14.046ZM31.6326 12.5878C31.6326 12.4345 31.5943 12.3103 31.5176 12.2152C31.444 12.1171 31.3367 12.068 31.1956 12.068C31.0576 12.068 30.9426 12.1109 30.8506 12.1968C30.7617 12.2827 30.7019 12.413 30.6712 12.5878H31.6326Z"
        fill="#212529"
      />
      <path
        d="M32.4029 11.746H32.7295L32.7801 12.1232C32.8813 11.976 32.9764 11.8687 33.0653 11.8012C33.1573 11.7337 33.2539 11.7 33.3551 11.7C33.395 11.7 33.4302 11.7046 33.4609 11.7138L33.4195 12.1692C33.3796 12.16 33.3336 12.1554 33.2815 12.1554C33.1803 12.1554 33.0852 12.1815 32.9963 12.2336C32.9074 12.2827 32.843 12.3409 32.8031 12.4084V14H32.4029V11.746Z"
        fill="#212529"
      />
      <path
        d="M34.7164 11.7C34.9494 11.7 35.1273 11.792 35.25 11.976C35.3757 12.1569 35.4386 12.4023 35.4386 12.712V14H35.0384V12.7626C35.0384 12.5234 35.0031 12.3471 34.9326 12.2336C34.8651 12.1171 34.7624 12.0588 34.6244 12.0588C34.5232 12.0588 34.4296 12.0879 34.3438 12.1462C34.2579 12.2014 34.1858 12.2796 34.1276 12.3808V14H33.7274V11.746H34.0678L34.0954 12.0266C34.1598 11.9254 34.2456 11.8457 34.353 11.7874C34.4634 11.7291 34.5845 11.7 34.7164 11.7Z"
        fill="#212529"
      />
      <path
        d="M36.836 14.046C36.6091 14.046 36.4144 13.9939 36.2518 13.8896C36.0924 13.7823 35.9712 13.6381 35.8884 13.4572C35.8087 13.2763 35.7688 13.0754 35.7688 12.8546C35.7688 12.643 35.8056 12.4498 35.8792 12.275C35.9559 12.0971 36.0647 11.9576 36.2058 11.8564C36.3499 11.7521 36.5202 11.7 36.7164 11.7C36.9679 11.7 37.1718 11.7843 37.3282 11.953C37.4877 12.1217 37.5674 12.3547 37.5674 12.6522C37.5674 12.7043 37.5613 12.7764 37.549 12.8684H36.1782C36.1874 13.1352 36.2533 13.3376 36.376 13.4756C36.5017 13.6136 36.6643 13.6826 36.8636 13.6826C36.9587 13.6826 37.0584 13.6719 37.1626 13.6504C37.27 13.6259 37.3635 13.5921 37.4432 13.5492L37.48 13.908C37.3114 14 37.0967 14.046 36.836 14.046ZM37.158 12.5878C37.158 12.4345 37.1197 12.3103 37.043 12.2152C36.9694 12.1171 36.8621 12.068 36.721 12.068C36.583 12.068 36.468 12.1109 36.376 12.1968C36.2871 12.2827 36.2273 12.413 36.1966 12.5878H37.158Z"
        fill="#212529"
      />
      <path
        d="M38.3653 14.046C38.1936 14.046 38.0525 13.9847 37.9421 13.862C37.8317 13.7393 37.7765 13.5829 37.7765 13.3928C37.7765 13.1107 37.8946 12.9083 38.1307 12.7856C38.3668 12.6599 38.6306 12.5939 38.9219 12.5878C38.9219 12.3915 38.8928 12.2551 38.8345 12.1784C38.7762 12.1017 38.6781 12.0634 38.5401 12.0634C38.3622 12.0634 38.1813 12.1324 37.9973 12.2704L37.9467 11.9346C38.1399 11.7782 38.3638 11.7 38.6183 11.7C38.8606 11.7 39.04 11.7705 39.1565 11.9116C39.273 12.0496 39.3313 12.2735 39.3313 12.5832V13.241C39.3313 13.4158 39.3436 13.54 39.3681 13.6136C39.3926 13.6841 39.4463 13.7194 39.5291 13.7194H39.5797L39.5291 14.046H39.4417C39.3006 14.046 39.1964 14.0199 39.1289 13.9678C39.0614 13.9157 39.0062 13.8313 38.9633 13.7148C38.8928 13.816 38.8054 13.8973 38.7011 13.9586C38.5968 14.0169 38.4849 14.046 38.3653 14.046ZM38.4895 13.7194C38.5631 13.7194 38.6398 13.6918 38.7195 13.6366C38.7992 13.5783 38.8667 13.5032 38.9219 13.4112V12.873C38.6858 12.8853 38.5064 12.9313 38.3837 13.011C38.261 13.0877 38.1997 13.2073 38.1997 13.3698C38.1997 13.6029 38.2963 13.7194 38.4895 13.7194Z"
        fill="#212529"
      />
      <path
        d="M40.5399 14.046C40.3651 14.046 40.2347 13.9939 40.1489 13.8896C40.0661 13.7853 40.0247 13.6581 40.0247 13.5078V12.0634H39.7119L39.7487 11.746H40.0247V11.1894L40.4249 11.148V11.746H40.9125V12.0634H40.4249V13.3468C40.4249 13.4848 40.4494 13.5814 40.4985 13.6366C40.5506 13.6918 40.6395 13.7194 40.7653 13.7194H40.9079L40.8711 14.046H40.5399Z"
        fill="#212529"
      />
      <path
        d="M42.255 11.7C42.4881 11.7 42.6659 11.792 42.7886 11.976C42.9143 12.1569 42.9772 12.4023 42.9772 12.712V14H42.577V12.7626C42.577 12.5234 42.5433 12.3486 42.4758 12.2382C42.4083 12.1247 42.3041 12.068 42.163 12.068C42.0587 12.068 41.9637 12.0971 41.8778 12.1554C41.795 12.2106 41.7245 12.2888 41.6662 12.39V14H41.266V10.55H41.6662V11.9852C41.7306 11.8963 41.8149 11.8273 41.9192 11.7782C42.0235 11.7261 42.1354 11.7 42.255 11.7Z"
        fill="#212529"
      />
      <path
        d="M44.7801 14.046C44.6084 14.046 44.4673 13.9847 44.3569 13.862C44.2465 13.7393 44.1913 13.5829 44.1913 13.3928C44.1913 13.1107 44.3094 12.9083 44.5455 12.7856C44.7817 12.6599 45.0454 12.5939 45.3367 12.5878C45.3367 12.3915 45.3076 12.2551 45.2493 12.1784C45.1911 12.1017 45.0929 12.0634 44.9549 12.0634C44.7771 12.0634 44.5961 12.1324 44.4121 12.2704L44.3615 11.9346C44.5547 11.7782 44.7786 11.7 45.0331 11.7C45.2754 11.7 45.4548 11.7705 45.5713 11.9116C45.6879 12.0496 45.7461 12.2735 45.7461 12.5832V13.241C45.7461 13.4158 45.7584 13.54 45.7829 13.6136C45.8075 13.6841 45.8611 13.7194 45.9439 13.7194H45.9945L45.9439 14.046H45.8565C45.7155 14.046 45.6112 14.0199 45.5437 13.9678C45.4763 13.9157 45.4211 13.8313 45.3781 13.7148C45.3076 13.816 45.2202 13.8973 45.1159 13.9586C45.0117 14.0169 44.8997 14.046 44.7801 14.046ZM44.9043 13.7194C44.9779 13.7194 45.0546 13.6918 45.1343 13.6366C45.2141 13.5783 45.2815 13.5032 45.3367 13.4112V12.873C45.1006 12.8853 44.9212 12.9313 44.7985 13.011C44.6759 13.0877 44.6145 13.2073 44.6145 13.3698C44.6145 13.6029 44.7111 13.7194 44.9043 13.7194Z"
        fill="#212529"
      />
      <path
        d="M47.2945 11.7C47.5275 11.7 47.7054 11.792 47.8281 11.976C47.9538 12.1569 48.0167 12.4023 48.0167 12.712V14H47.6165V12.7626C47.6165 12.5234 47.5812 12.3471 47.5107 12.2336C47.4432 12.1171 47.3405 12.0588 47.2025 12.0588C47.1013 12.0588 47.0077 12.0879 46.9219 12.1462C46.836 12.2014 46.7639 12.2796 46.7057 12.3808V14H46.3055V11.746H46.6459L46.6735 12.0266C46.7379 11.9254 46.8237 11.8457 46.9311 11.7874C47.0415 11.7291 47.1626 11.7 47.2945 11.7Z"
        fill="#212529"
      />
      <path
        d="M49.3129 14.046C49.1105 14.046 48.9357 13.9954 48.7885 13.8942C48.6413 13.7899 48.5279 13.6489 48.4481 13.471C48.3715 13.2931 48.3331 13.0938 48.3331 12.873C48.3331 12.6522 48.3715 12.4529 48.4481 12.275C48.5279 12.0971 48.6413 11.9576 48.7885 11.8564C48.9357 11.7521 49.1105 11.7 49.3129 11.7C49.5123 11.7 49.6855 11.7521 49.8327 11.8564C49.9799 11.9576 50.0919 12.0971 50.1685 12.275C50.2452 12.4529 50.2835 12.6522 50.2835 12.873C50.2835 13.0938 50.2452 13.2931 50.1685 13.471C50.0919 13.6489 49.9799 13.7899 49.8327 13.8942C49.6855 13.9954 49.5123 14.046 49.3129 14.046ZM49.3129 13.6918C49.4908 13.6918 49.6288 13.6197 49.7269 13.4756C49.8251 13.3315 49.8741 13.1306 49.8741 12.873C49.8741 12.6154 49.8251 12.4161 49.7269 12.275C49.6288 12.1309 49.4908 12.0588 49.3129 12.0588C49.1289 12.0588 48.9879 12.1309 48.8897 12.275C48.7916 12.4161 48.7425 12.6154 48.7425 12.873C48.7425 13.1337 48.7916 13.3361 48.8897 13.4802C48.9879 13.6213 49.1289 13.6918 49.3129 13.6918Z"
        fill="#212529"
      />
      <path
        d="M51.2987 14.046C51.1239 14.046 50.9935 13.9939 50.9077 13.8896C50.8249 13.7853 50.7835 13.6581 50.7835 13.5078V12.0634H50.4707L50.5075 11.746H50.7835V11.1894L51.1837 11.148V11.746H51.6713V12.0634H51.1837V13.3468C51.1837 13.4848 51.2082 13.5814 51.2573 13.6366C51.3094 13.6918 51.3983 13.7194 51.5241 13.7194H51.6667L51.6299 14.046H51.2987Z"
        fill="#212529"
      />
      <path
        d="M53.0138 11.7C53.2469 11.7 53.4247 11.792 53.5474 11.976C53.6731 12.1569 53.736 12.4023 53.736 12.712V14H53.3358V12.7626C53.3358 12.5234 53.3021 12.3486 53.2346 12.2382C53.1671 12.1247 53.0629 12.068 52.9218 12.068C52.8175 12.068 52.7225 12.0971 52.6366 12.1554C52.5538 12.2106 52.4833 12.2888 52.425 12.39V14H52.0248V10.55H52.425V11.9852C52.4894 11.8963 52.5737 11.8273 52.678 11.7782C52.7823 11.7261 52.8942 11.7 53.0138 11.7Z"
        fill="#212529"
      />
      <path
        d="M55.1372 14.046C54.9103 14.046 54.7155 13.9939 54.553 13.8896C54.3935 13.7823 54.2724 13.6381 54.1896 13.4572C54.1099 13.2763 54.07 13.0754 54.07 12.8546C54.07 12.643 54.1068 12.4498 54.1804 12.275C54.2571 12.0971 54.3659 11.9576 54.507 11.8564C54.6511 11.7521 54.8213 11.7 55.0176 11.7C55.2691 11.7 55.473 11.7843 55.6294 11.953C55.7889 12.1217 55.8686 12.3547 55.8686 12.6522C55.8686 12.7043 55.8625 12.7764 55.8502 12.8684H54.4794C54.4886 13.1352 54.5545 13.3376 54.6772 13.4756C54.8029 13.6136 54.9655 13.6826 55.1648 13.6826C55.2599 13.6826 55.3595 13.6719 55.4638 13.6504C55.5711 13.6259 55.6647 13.5921 55.7444 13.5492L55.7812 13.908C55.6125 14 55.3979 14.046 55.1372 14.046ZM55.4592 12.5878C55.4592 12.4345 55.4209 12.3103 55.3442 12.2152C55.2706 12.1171 55.1633 12.068 55.0222 12.068C54.8842 12.068 54.7692 12.1109 54.6772 12.1968C54.5883 12.2827 54.5285 12.413 54.4978 12.5878H55.4592Z"
        fill="#212529"
      />
      <path
        d="M56.2295 11.746H56.5561L56.6067 12.1232C56.7079 11.976 56.8029 11.8687 56.8919 11.8012C56.9839 11.7337 57.0805 11.7 57.1817 11.7C57.2215 11.7 57.2568 11.7046 57.2875 11.7138L57.2461 12.1692C57.2062 12.16 57.1602 12.1554 57.1081 12.1554C57.0069 12.1554 56.9118 12.1815 56.8229 12.2336C56.7339 12.2827 56.6695 12.3409 56.6297 12.4084V14H56.2295V11.746Z"
        fill="#212529"
      />
      <path
        d="M59.0551 14.046C58.7913 14.046 58.5629 13.9847 58.3697 13.862L58.4203 13.4572C58.6165 13.6105 58.8189 13.6872 59.0275 13.6872C59.1379 13.6872 59.2237 13.6642 59.2851 13.6182C59.3495 13.5722 59.3817 13.5047 59.3817 13.4158C59.3817 13.3483 59.3663 13.2931 59.3357 13.2502C59.3081 13.2073 59.2605 13.1659 59.1931 13.126C59.1256 13.0861 59.0213 13.034 58.8803 12.9696C58.7147 12.8929 58.5935 12.8055 58.5169 12.7074C58.4402 12.6093 58.4019 12.482 58.4019 12.3256C58.4019 12.1416 58.4663 11.9913 58.5951 11.8748C58.7239 11.7583 58.8971 11.7 59.1149 11.7C59.3357 11.7 59.5289 11.7491 59.6945 11.8472L59.6439 12.2198C59.5611 12.1646 59.4829 12.1247 59.4093 12.1002C59.3357 12.0726 59.2483 12.0588 59.1471 12.0588C59.0397 12.0588 58.9554 12.0818 58.8941 12.1278C58.8327 12.1738 58.8021 12.2382 58.8021 12.321C58.8021 12.4038 58.8281 12.4667 58.8803 12.5096C58.9355 12.5525 59.0413 12.6093 59.1977 12.6798C59.4154 12.7779 59.5687 12.8776 59.6577 12.9788C59.7466 13.0769 59.7911 13.2073 59.7911 13.3698C59.7911 13.5814 59.7251 13.747 59.5933 13.8666C59.4614 13.9862 59.282 14.046 59.0551 14.046Z"
        fill="#212529"
      />
      <path
        d="M60.7817 14.046C60.6069 14.046 60.4765 13.9939 60.3907 13.8896C60.3079 13.7853 60.2665 13.6581 60.2665 13.5078V12.0634H59.9537L59.9905 11.746H60.2665V11.1894L60.6667 11.148V11.746H61.1543V12.0634H60.6667V13.3468C60.6667 13.4848 60.6912 13.5814 60.7403 13.6366C60.7924 13.6918 60.8813 13.7194 61.0071 13.7194H61.1497L61.1129 14.046H60.7817Z"
        fill="#212529"
      />
      <path
        d="M61.5124 10.78H61.9126V11.2354H61.5124V10.78ZM61.5124 11.746H61.9126V14H61.5124V11.746Z"
        fill="#212529"
      />
      <path
        d="M63.3437 14.046C63.0002 14.046 62.7395 13.9387 62.5617 13.724C62.3838 13.5063 62.2949 13.2272 62.2949 12.8868C62.2949 12.5341 62.3838 12.2489 62.5617 12.0312C62.7395 11.8104 63.0033 11.7 63.3529 11.7C63.5399 11.7 63.7071 11.7383 63.8543 11.815L63.7899 12.1692C63.6519 12.0956 63.5077 12.0588 63.3575 12.0588C63.152 12.0588 62.991 12.1309 62.8745 12.275C62.761 12.4161 62.7043 12.6139 62.7043 12.8684C62.7043 13.1229 62.7625 13.3238 62.8791 13.471C62.9987 13.6182 63.1627 13.6918 63.3713 13.6918C63.5307 13.6918 63.6718 13.655 63.7945 13.5814L63.8497 13.9402C63.7883 13.9709 63.7117 13.9954 63.6197 14.0138C63.5277 14.0353 63.4357 14.046 63.3437 14.046Z"
        fill="#212529"
      />
      <path
        d="M64.235 10.55H64.6352V12.7672L65.3114 11.746H65.7898L65.0446 12.7856L65.8036 14H65.3206L64.6352 12.85V14H64.235V10.55Z"
        fill="#212529"
      />
      <path
        d="M4.5658 11.8372L4.0046 11.4784L4.1334 11.2622L4.6532 11.6624L4.621 11H4.851L4.8188 11.6624L5.3386 11.2668L5.4674 11.4784L4.9108 11.8372L5.472 12.196L5.3432 12.4122L4.8188 12.0166L4.851 12.6698H4.621L4.6532 12.0166L4.1288 12.4122L4 12.2006L4.5658 11.8372Z"
        fill="#CA393B"
      />
      <defs>
        <filter
          id="filter0_d_283_995"
          x="7.65826"
          y="33.0895"
          width="48.7597"
          height="8.7932"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_283_995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_283_995"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_283_995"
          x="9.55167"
          y="26.6006"
          width="46.4252"
          height="20.8536"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_283_995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_283_995"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_283_995"
          x="22.9034"
          y="25.0738"
          width="38.4344"
          height="32.7636"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_283_995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_283_995"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_283_995"
          x="11.2026"
          y="25.7043"
          width="48.7597"
          height="8.7932"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_283_995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_283_995"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_283_995"
          x="14.9283"
          y="18.7278"
          width="38.6436"
          height="32.5558"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_283_995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_283_995"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_283_995"
          x="17.4845"
          y="31.3223"
          width="47.9384"
          height="16.0222"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_283_995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_283_995"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_283_995"
          x="11.3308"
          y="24.2275"
          width="46.0389"
          height="21.7785"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_283_995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_283_995"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_d_283_995"
          x="7.74329"
          y="22.6021"
          width="47.7959"
          height="16.6161"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_283_995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_283_995"
            result="shape"
          />
        </filter>
        <filter
          id="filter8_d_283_995"
          x="5.16742"
          y="27.4387"
          width="42.2721"
          height="28.3252"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_283_995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_283_995"
            result="shape"
          />
        </filter>
        <filter
          id="filter9_d_283_995"
          x="13.8608"
          y="21.7383"
          width="48.7597"
          height="8.7932"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_283_995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_283_995"
            result="shape"
          />
        </filter>
        <filter
          id="filter10_d_283_995"
          x="9.43042"
          y="31.2567"
          width="48.7597"
          height="8.7932"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_283_995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_283_995"
            result="shape"
          />
        </filter>
        <filter
          id="filter11_d_283_995"
          x="10.4132"
          y="31.3374"
          width="47.4505"
          height="17.889"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_283_995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_283_995"
            result="shape"
          />
        </filter>
        <filter
          id="filter12_d_283_995"
          x="11.2026"
          y="29.6703"
          width="48.7597"
          height="8.7932"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_283_995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_283_995"
            result="shape"
          />
        </filter>
        <filter
          id="filter13_d_283_995"
          x="14.9292"
          y="17.9349"
          width="33.8479"
          height="36.6207"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_283_995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_283_995"
            result="shape"
          />
        </filter>
        <filter
          id="filter14_d_283_995"
          x="9.50949"
          y="35.2881"
          width="47.9461"
          height="15.9886"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_283_995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_283_995"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default SticksGame;
