import React from "react";

interface IconProps {
  className?: string;
}

const GameIcon = (props: IconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 71 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="71" height="52" rx="2" fill="#D9D9D9" />
      <g filter="url(#filter0_d_283_985)">
        <path
          d="M55.4288 32.1846C54.3428 28.1727 53.4631 24.6203 52.5063 20.833C51.0782 15.1836 49.7778 14.2658 44.7102 14.0174C43.5383 13.96 42.0086 13.9529 41.034 15.5389C40.6026 16.2408 39.8723 16.1605 39.187 16.1592H31.4217C30.7364 16.1605 30.0057 16.2729 29.5745 15.5709C28.6003 13.9848 27.0704 13.992 25.8985 14.0494C20.8303 14.2979 19.5372 15.2174 18.1028 20.865C17.3813 23.7053 15.928 29.3832 15.1799 32.2166C14.704 34.0191 15.1274 35.1693 16.9907 36.5193C18.2983 37.4672 20.3405 37.3959 21.5969 36.2012C22.9307 34.9324 23.7575 33.3977 25.0092 31.8643C25.6024 31.1379 26.1653 30.7281 27.5081 31.1707C30.1467 32.0412 32.6458 32.1393 34.8739 32.158C37.611 32.2182 40.4622 32.0092 43.1004 31.1387C44.4432 30.6957 45.0063 31.1059 45.5995 31.8326C46.851 33.3658 47.6778 34.9004 49.0118 36.1691C50.2684 37.3639 52.3104 37.4348 53.618 36.4873C55.4815 35.1373 55.9161 33.9844 55.4288 32.1846Z"
          fill="#373A41"
        />
        <path
          d="M30.4063 21.9271H28.3698V19.8906C28.3698 19.291 27.8836 18.8045 27.2838 18.8045H26.4393C25.8395 18.8045 25.3532 19.2908 25.3532 19.8906V21.9271H23.3168C22.7172 21.9271 22.2307 22.4133 22.2307 23.0133V23.8578C22.2307 24.4576 22.717 24.9439 23.3168 24.9439H25.3534V26.9805C25.3534 27.5801 25.8395 28.0666 26.4395 28.0666H27.284C27.8838 28.0666 28.37 27.5805 28.37 26.9805V24.9437H30.4063C31.0061 24.9437 31.4922 24.4576 31.4922 23.8576V23.0131C31.4922 22.4133 31.0061 21.9271 30.4063 21.9271Z"
          fill="white"
        />
        <path
          d="M43.1425 22.8338C44.2691 22.8338 45.1825 21.9204 45.1825 20.7937C45.1825 19.6671 44.2691 18.7537 43.1425 18.7537C42.0158 18.7537 41.1024 19.6671 41.1024 20.7937C41.1024 21.9204 42.0158 22.8338 43.1425 22.8338Z"
          fill="white"
        />
        <path
          d="M40.1725 27.9385C41.2991 27.9385 42.2124 27.0252 42.2124 25.8986C42.2124 24.7721 41.2991 23.8588 40.1725 23.8588C39.046 23.8588 38.1327 24.7721 38.1327 25.8986C38.1327 27.0252 39.046 27.9385 40.1725 27.9385Z"
          fill="white"
        />
        <path
          d="M46.1126 27.9383C47.2391 27.9383 48.1522 27.0251 48.1522 25.8986C48.1522 24.7722 47.2391 23.859 46.1126 23.859C44.9861 23.859 44.0729 24.7722 44.0729 25.8986C44.0729 27.0251 44.9861 27.9383 46.1126 27.9383Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_283_985"
          x="11"
          y="14"
          width="48.6135"
          height="31.1704"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_283_985"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_283_985"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default GameIcon;
