import React from "react";

const ErrorIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 12C8.22667 12 8.4168 11.9232 8.5704 11.7696C8.72347 11.6165 8.8 11.4267 8.8 11.2C8.8 10.9733 8.72347 10.7832 8.5704 10.6296C8.4168 10.4765 8.22667 10.4 8 10.4C7.77333 10.4 7.58347 10.4765 7.4304 10.6296C7.2768 10.7832 7.2 10.9733 7.2 11.2C7.2 11.4267 7.2768 11.6165 7.4304 11.7696C7.58347 11.9232 7.77333 12 8 12ZM8 8.8C8.22667 8.8 8.4168 8.7232 8.5704 8.5696C8.72347 8.41653 8.8 8.22667 8.8 8V4.8C8.8 4.57333 8.72347 4.3832 8.5704 4.2296C8.4168 4.07653 8.22667 4 8 4C7.77333 4 7.58347 4.07653 7.4304 4.2296C7.2768 4.3832 7.2 4.57333 7.2 4.8V8C7.2 8.22667 7.2768 8.41653 7.4304 8.5696C7.58347 8.7232 7.77333 8.8 8 8.8ZM8 16C6.89333 16 5.85333 15.7899 4.88 15.3696C3.90667 14.9499 3.06 14.38 2.34 13.66C1.62 12.94 1.05013 12.0933 0.6304 11.12C0.210133 10.1467 0 9.10667 0 8C0 6.89333 0.210133 5.85333 0.6304 4.88C1.05013 3.90667 1.62 3.06 2.34 2.34C3.06 1.62 3.90667 1.04987 4.88 0.6296C5.85333 0.209867 6.89333 0 8 0C9.10667 0 10.1467 0.209867 11.12 0.6296C12.0933 1.04987 12.94 1.62 13.66 2.34C14.38 3.06 14.9499 3.90667 15.3696 4.88C15.7899 5.85333 16 6.89333 16 8C16 9.10667 15.7899 10.1467 15.3696 11.12C14.9499 12.0933 14.38 12.94 13.66 13.66C12.94 14.38 12.0933 14.9499 11.12 15.3696C10.1467 15.7899 9.10667 16 8 16ZM8 14.4C9.78667 14.4 11.3 13.78 12.54 12.54C13.78 11.3 14.4 9.78667 14.4 8C14.4 6.21333 13.78 4.7 12.54 3.46C11.3 2.22 9.78667 1.6 8 1.6C6.21333 1.6 4.7 2.22 3.46 3.46C2.22 4.7 1.6 6.21333 1.6 8C1.6 9.78667 2.22 11.3 3.46 12.54C4.7 13.78 6.21333 14.4 8 14.4Z"
        fill="#FFC9C9"
      />
    </svg>
  );
};

export default ErrorIcon;
